<template>
  <div class="d-flex flex-column align-end">
    <div v-if="first" class="px-2 mb-1 text-right caption d-flex align-center">
      {{ displayName }}
      <message-avatar v-if="showAvatar" :name="displayName"></message-avatar>
    </div>
    <div
      class="
        blue
        darken-1
        white--text
        lighten-3
        py-2
        px-3
        font-weight-regular
        body-2
        rounded-tl-lg rounded-bl-lg
        content
      "
      :class="messageClass"
    >
      <slot></slot>
    </div>
    <div v-if="last" class="px-3 text-right caption">
      <span class="delivered">
        <v-icon color="green" small class="mt-n1">mdi-check</v-icon>
        <span class="ml-1 grey--text text--darken-1">Delivered •</span>
      </span>
      <span class="ml-1 grey--text text--darken-1">{{ time(timestamp) }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content {
  white-space: pre-wrap;
  max-width: 90%;
  line-height: 17px;
}

.divider {
  margin-top: 1px;
}
</style>

<script>
import { defineComponent } from '@vue/composition-api'
import MessageAvatar from './MessageAvatar.vue'
import dayjs from 'dayjs'

export default defineComponent({
  components: { MessageAvatar },
  props: {
    showAvatar: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    displayName: {
      type: String,
      default: '',
    },
    first: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
    timestamp: { type: String, default: '' },
  },
  setup(props) {
    const time = val => {
      return dayjs(val).format('h:mm a')
    }

    const messageClass = () => {
      let classes = []
      if (props.first) {
        classes.push('pt-2')
        classes.push('rounded-tr-lg')
      } else {
        classes.push('divider')
      }
      if (props.last) {
        classes.push('pb-2')
        classes.push('rounded-br-lg')
      }
      return classes.join(' ')
    }

    return {
      messageClass,
      time,
    }
  },
})
</script>