<template>
  <v-card
    ref="messengerRef"
    class="messenger"
    :dark="dark"
    flat
    tile
    :height="height"
    :width="width"
    v-resize="scrollToBottom"
  >
    <div class="absolute fill-height fill-width d-flex flex-column">
      <!-- Heros -->
      <div v-if="!value.length" class="d-flex flex-grow-1 align-center justify-center">
        <message-hero-no-messages />
      </div>

      <div ref="messagesRef" class="flex-grow-1 scrollable" v-scroll.self="handleScroll" :class="readyClass">
        <div
          v-for="(messageGroup, messageGroupIndex) in messageGroups"
          :key="messageGroupIndex"
          :class="{
            'message-group-header': messageGroup.isHeader,
            'message-group py-2': !messageGroup.isHeader,
          }"
        >
          <div class="message-group-item" v-for="item in messageGroup" :key="item.id">
            <!-- Timestamp -->
            <message-timestamp v-if="item.isTimestamp" :timestamp="item.created"></message-timestamp>

            <!-- Sender -->
            <message-sender
              v-else-if="item.isSender"
              :start="true"
              :displayName="item.displayName"
              :first="item.isFirst"
              :last="item.isLast"
              :timestamp="item.created"
              :delivered="item.delivered"
              class="message px-2"
              v-intersect="handleIntersect"
              >{{ item.content }}</message-sender
            >

            <!-- Receiver -->
            <message-receiver
              v-else
              :displayName="item.displayName"
              :first="item.isFirst"
              :last="item.isLast"
              :timestamp="item.created"
              class="message px-2"
              v-intersect="handleIntersect"
              >{{ item.content }}</message-receiver
            >
          </div>
        </div>
      </div>

      <!-- Message Form -->
      <v-scroll-y-reverse-transition>
        <message-form
          v-show="!readonly"
          v-mutate="scrollToBottom"
          ref="messageFormRef"
          class="form"
          :dark="dark"
          :disabled="disabled"
          :text="content"
          @submit="handleSubmit"
        ></message-form>
      </v-scroll-y-reverse-transition>

      <!-- Scroll To Bottom Button -->
      <div class="absolute d-flex justify-center fill-width scroll-btn">
        <v-slide-y-transition>
          <v-btn v-if="showScrollToBottom" rounded elevation="1" @click="scrollToBottom(true)">
            <v-icon>mdi-arrow-down</v-icon>
            <v-slide-x-transition>
              <span v-show="newMessageCount">{{ newMessageCount }} New</span>
            </v-slide-x-transition>
          </v-btn>
        </v-slide-y-transition>
      </div>
    </div>
  </v-card>
</template>

<style lang="css" scoped>
.scrollable {
  overflow-y: auto;
}

.absolute {
  position: absolute;
}

.fill-width {
  width: 100%;
}

.hidden {
  display: none;
  /* visibility: hidden; */
}

.scroll-btn {
  bottom: 80px;
  z-index: 2;
}

.message-group {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 975px;
}

.messenger >>> .delivered {
  display: none;
}

.message-group:last-child .message-group-item:last-child >>> .delivered {
  display: inline;
}

.message-group-header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
}
</style>

<script>
import { defineComponent, ref, toRefs, watch, onBeforeMount } from '@vue/composition-api'
import MessageHeroNoMessages from './MessageHeroNoMessages.vue'
import MessageReceiver from './MessageReceiver.vue'
import MessageSender from './MessageSender.vue'
import MessageTimestamp from './MessageTimestamp.vue'
import MessageForm from './MessageForm.vue'
import goTo from 'vuetify/es5/services/goto'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'

export default defineComponent({
  props: {
    // messages
    value: {
      type: Array,
      default: () => {
        return []
      },
    },
    content: {
      type: String,
      default: '',
    },
    /**
     * How long before a message is considered to be part of a new conversation.
     * Default is 5 minutes since the last message.
     */
    conversationTolerance: {
      type: Number,
      default: 5 * 60 * 1000, // 5 minutes
    },
    // enables dark mode
    dark: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: '100%',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    scrollDuration: {
      type: Number,
      default: 500,
    },
    scrollToBottomDistance: {
      type: Number,
      default: 120,
    },
    width: {
      type: [String, Number],
      default: '100%',
    },
    workerUrl: {
      type: String,
      default: '',
    },
  },
  components: {
    MessageHeroNoMessages,
    MessageTimestamp,
    MessageReceiver,
    MessageSender,
    MessageForm,
  },
  setup(props, ctx) {
    // ref elements
    const messengerRef = ref(null)
    const messageFormRef = ref(null)
    const messagesRef = ref(null)

    // vars
    const messageGroups = ref([])
    const newMessageCount = ref(0)
    const showScrollToBottom = ref(false)
    const readyClass = ref('hidden')
    const submissions = []

    let autoScroll = true
    let worker

    const internalMessageWorker = ({ messages, conversationTolerance }) => {
      console.warn('Not optimized: using internal message worker')
      const msgGroups = []
      let msgGroup = []
      const dayCache = {}
      for (let i = 0; i < messages.length; i++) {
        let curMessage = messages[i]
        if (i > 0) {
          let prevMessage = messages[i - 1] || {}
          prevMessage.isLast = false
          let dateDiff = new Date(curMessage.created) - new Date(prevMessage.created)
          if (curMessage.senderId !== prevMessage.senderId) {
            prevMessage.isLast = true
            msgGroups.push(msgGroup)
            msgGroup = []
          } else if (dateDiff >= conversationTolerance) {
            prevMessage.isLast = true
            msgGroups.push(msgGroup)
            msgGroup = []
          }
        }

        let day = dayjs(curMessage.created).format('MM/DD/YYYY')
        if (!dayCache[day]) {
          dayCache[day] = true
          const mg = [
            {
              isTimestamp: true,
              created: curMessage.created,
            },
          ]
          mg.isHeader = true
          msgGroups.push(mg)
        }

        let index = msgGroup.push(curMessage)
        curMessage.isFirst = index === 1 || (index === 2 && msgGroup[0].isTimestamp)
      }
      if (msgGroup[msgGroup.length - 1]) {
        msgGroup[msgGroup.length - 1].isLast = true
      }
      msgGroups.push(msgGroup)
      return msgGroups
    }

    const handleIntersect = entries => {
      entries[0].target.style.display = entries[0].isIntersecting ? '' : 'none'
    }

    /**
     * This will scroll the messages back to the bottom. Update scroll
     * will not scroll to the bottom if autoScroll is off, so scroll
     * to bottom can force the auto scroll. AutoScroll is disabled
     * if the user manually scroll up the messages. It finds the last
     * message element and scrolls to that element
     */
    let scrollTimer
    let scrollDuration = 0 // start with scroll to immediately
    const scrollToBottom = debounce(force => {
      if (force === true || autoScroll) {
        let messageEls = messengerRef.value?.$el.querySelectorAll('.message')
        if (messageEls?.length > 0) {
          readyClass.value = ''
          goTo(messageEls[messageEls.length - 1], {
            duration: scrollDuration,
            container: messagesRef.value,
          })
          clearTimeout(scrollTimer)
          scrollTimer = setTimeout(() => {
            scrollDuration = props.scrollDuration
          }, props.scrollDuration)
        }
      }
    }, 100)

    /**
     * Used to determine if autoScroll should be enabled or disabled
     */
    const handleScroll = evt => {
      let t = evt.target
      showScrollToBottom.value = t.scrollHeight - t.offsetHeight - t.scrollTop > props.scrollToBottomDistance
      autoScroll = !showScrollToBottom.value
    }

    /**
     * Groups messages based on sender and time. The preparsing makes it
     * easier to render the items in the HTML above. If a worker is present
     * then it will use it otherwise it will use the internal parser.
     */
    const parseMessages = messages => {
      if (worker) {
        return worker.postMessage({
          messages,
          conversationTolerance: props.conversationTolerance,
        })
      }

      messageGroups.value = internalMessageWorker({
        messages,
        conversationTolerance: props.conversationTolerance,
      })
    }

    /**
     * Avoids spamming by create a single text block out of many
     * if there are multiple submissions made
     */
    const submitMessages = debounce(() => {
      autoScroll = true
      showScrollToBottom.value = false
      ctx.emit('submit', submissions.join(' '))
    }, 300)

    const handleSubmit = text => {
      submissions.push(text.trim())
      submitMessages()
    }

    const invalidateMessages = (newMsgs = [], oldMsgs = []) => {
      parseMessages(newMsgs)
      if (showScrollToBottom.value) {
        let diff = newMsgs.length - oldMsgs.length
        newMessageCount.value += diff
      }
      if (!worker) {
        scrollToBottom()
      }
    }

    const msgs = toRefs(props).value
    watch(msgs, (newVal, oldVal) => {
      invalidateMessages(newVal, oldVal)
    })

    watch(showScrollToBottom, newVal => {
      if (!newVal) {
        setTimeout(() => {
          newMessageCount.value = 0
        }, scrollDuration)
      }
    })

    watch(readyClass, classVal => {
      if (!classVal) {
        scrollToBottom()
      }
    })

    onBeforeMount(() => {
      invalidateMessages(msgs.value)
      submissions.length = 0
    })

    return {
      // refs
      messengerRef,
      messageFormRef,
      messagesRef,
      // props
      messageGroups,
      newMessageCount,
      showScrollToBottom,
      worker,
      // methods
      handleSubmit,
      handleIntersect,
      handleScroll,
      readyClass,
      scrollToBottom,
    }
  },
})
</script>