<template>
  <v-row no-gutters justify="center" align="center">
    <v-card
      flat
      min-width="150"
      class="subtitle-2 font-weight-medium px-2 text-center mb-2 py-1 px-16"
      >{{ ts }}</v-card
    >
  </v-row>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import dayjs from 'dayjs'

export default defineComponent({
  props: {
    timestamp: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const ts = computed(() => {
      if (dayjs(props.timestamp).format('MM/DD/YYYY') === dayjs().format('MM/DD/YYYY')) {
        return 'Today' // + dayjs(props.timestamp).format('h:mm a')
      }
      return dayjs(props.timestamp).format('dddd, MMMM D')
    })
    return {
      ts,
    }
  },
})
</script>
