<!-- 
MessageForm

The message form has the following features:
- Autogrow as more text is entered
- ENTER - submits
- CTRL+ENTER - new line
- disable puts text into a readonly mode and hides the default button
- There is slot to put a different button (action)
-->
<template>
  <v-card class="message-form" flat tile>
    <v-textarea
      id="messenger-textarea"
      rows="2"
      solo
      flat
      auto-grow
      no-resize
      hide-details
      :placeholder="disabled ? '' : 'Type message'"
      class="message-textarea you_are_here pa-2"
      v-model="content"
      :readonly="disabled"
      @keypress="handleKeyPress"
    >
      <div slot="append">
        <slot>
          <v-btn v-if="!disabled" depressed dark color="green" @click="submit">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </slot>
      </div>
    </v-textarea>
  </v-card>
</template>

<style lang="css">
.message-form {
  background-color: #e0e0e0 !important;
}

.theme--dark .message-form {
  background-color: #333333 !important;
}

#messenger-textarea {
  max-height: 135px;
  overflow-y: auto;
  font-size: 14px;
  line-height: 17px;
}
</style>

<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  setup(props, ctx) {
    const content = ref(props.text || '')

    const handleKeyPress = evt => {
      if (evt.keyCode == 13 && !evt.shiftKey) {
        evt.preventDefault()
        submit()
        return true
      }
    }

    const submit = () => {
      let txt = content.value.trim()
      content.value = ''
      if (txt.length) {
        ctx.emit('submit', txt)
      }
    }

    return {
      content,
      handleKeyPress,
      submit,
    }
  },
})
</script>