import { render, staticRenderFns } from "./Messenger.vue?vue&type=template&id=5d5dc54e&scoped=true&"
import script from "./Messenger.vue?vue&type=script&lang=js&"
export * from "./Messenger.vue?vue&type=script&lang=js&"
import style0 from "./Messenger.vue?vue&type=style&index=0&id=5d5dc54e&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d5dc54e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VScrollYReverseTransition } from 'vuetify/lib/components/transitions';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBtn,VCard,VIcon,VScrollYReverseTransition,VSlideXTransition,VSlideYTransition})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
import Mutate from 'vuetify/lib/directives/mutate'
import Resize from 'vuetify/lib/directives/resize'
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Intersect,Mutate,Resize,Scroll})
