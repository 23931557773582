<template>
  <div
    class="ml-2 rounded-circle d-flex align-center justify-center white--text"
    style="width: 24px; height: 24px"
    :style="{ backgroundColor: getBGColor(name) }"
  >
    <span>{{ first(name) }}</span>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: ['name'],
  setup() {
    const colors = [
      '#F44336',
      '#9C27B0',
      '#2196F3',
      '#03A9F4',
      '#009688',
      '#4CAF50',
      '#8BC34A',
      '#FFC107',
      '#FF9800',
      '#FF5722',
    ]

    const numberFromText = text => {
      // numberFromText("AA");
      const charCodes = text
        .split('') // => ["A", "A"]
        .map(char => char.charCodeAt(0)) // => [65, 65]
        .join('') // => "6565"
      return parseInt(charCodes, 10)
    }

    const first = val => {
      return val.substr(0, 1)
    }

    const getBGColor = val => {
      let firstLetter = first(val)
      let num = numberFromText(firstLetter)
      return colors[num % colors.length]
    }

    return {
      getBGColor,
      first,
    }
  },
})
</script>