<template>
  <div class="d-flex flex-column align-start">
    <div v-if="first" class="px-2 pb-1 text-right caption">{{ displayName }}</div>
    <div
      class="rounded-tr-lg rounded-br-lg lighten-3 py-2 px-3 font-weight-regular body-2 content"
      :class="messageClass"
    >
      <slot></slot>
    </div>
    <div v-if="last" class="px-2 caption">
      <span class="grey--text">{{ time(timestamp) }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content {
  background-color: #eee;
  white-space: pre-wrap;
  max-width: 90%;
  line-height: 17px;
}

.theme--dark .content {
  background-color: #333;
}

.divider {
  margin-top: 1px;
}
</style>

<style lang="css">
.theme--dark .receiver .grey {
  background-color: #424242 !important;
}
</style>

<script>
import { defineComponent } from '@vue/composition-api'
import dayjs from 'dayjs'

export default defineComponent({
  props: {
    displayName: {
      type: String,
      default: '',
    },
    first: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
    timestamp: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const time = val => {
      return dayjs(val).format('h:mm a')
    }

    const messageClass = () => {
      let classes = []
      if (props.first) {
        classes.push('pt-3')
        classes.push('rounded-tl-lg')
      } else {
        classes.push('divider')
      }

      if (props.last) {
        classes.push('pb-3')
        classes.push('rounded-bl-lg')
      }
      return classes.join(' ')
    }

    return {
      messageClass,
      time,
    }
  },
})
</script>